<template>
	<div class="page">
		<div style="margin-top: 30px;position: relative;">
			<iframe src="https://wancode.ke.qq.com" class="t-iframe" scrolling="no" security="restricted" sandbox="" width="1280px" height="500px"></iframe>
			<div @click="jumpToTXKT" style="width: 1280px;height: 500px;background-color: #FFFFFF;opacity: 0;position: absolute;top: 0;left: 0;z-index: 998;">

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		methods: {
			jumpToTXKT() {
				this.$alert('即将跳转至腾讯课堂', '跳转', {
					confirmButtonText: '确定',
					callback: action => {
						var tempwindow = window.open();
						tempwindow.location = 'https://wancode.ke.qq.com';
					}
				})
			}
		}
	};
</script>

<style scoped></style>
